<template>
  <div class="backbar">
      <div class="block"></div>
        <div class="head">
            <a href="javascript:;" @click="$router.back(-1)" class="bar-item"><img src="~@/assets/img/back.png"></a>
            <div class="page_tit">{{pageTit}}</div>
            <a class="page_index" @click="$router.push({path:'/'})"><img src="~@/assets/img/indexIco.png"></a>
        </div>
  </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    props:{
        pageTit: String
    }

}
</script>
<style lang="less" scoped>
    .backbar{
        .head{
            height: .88rem;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .bar-item{
            padding:0 .32rem;
            height: 100%;
            display: flex;
            align-items: center;
            img{
                width: .32rem;
                height: .32rem;
            }
        }
        .page_tit{
            font-size: .32rem;
            font-weight: 400;
            color: #333333;
        }
        .page_index{
            padding:0 .32rem;
            height: 100%;
            display: flex;
            align-items: center;
            img{
                width: .28rem;
                height: .28rem;
            }
        }
    }

</style>
